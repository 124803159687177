<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-02-28 17:08:33
 * @ Description: Component for rating and reviewing a company/individual.
 -->

<template>
    <div>
        <v-card
            class="rounded-lg"
        >
            <v-card-text
                style="color: black;"
            >
                <v-form
                    ref="reviewForm"
                    v-model="validReviewForm"
                >
                    <!-- Review Not Completed -->
                    <v-row
                        justify="center"
                        v-if="!reviewSubmitted"
                    >
                        <v-col
                            cols="12"
                            class="os-15-sb pb-1"
                        >
                            Rating & Review
                        </v-col>
                        <v-col
                            cols="12"
                            class="os-13-r pt-0"
                        >
                            Please complete the rating and provide a brief review of your experience with working with <span class="os-13-sb">{{ revieweeName }}</span> on this project. Honest reviews and ratings help other Fixers!
                        </v-col>

                        <!-- Rating -->
                        <v-col
                            cols="12"
                            class="os-15-sb"
                        >
                            Rating
                        </v-col>
                        <v-col
                            cols="12"
                            class="os-13-r pt-0"
                            style="color: #8F9BB3; line-height: 1;"
                        >
                            <v-row>
                                <v-col
                                    class="text-center pa-1"
                                    style="width: 55px; max-width: 55px;"
                                    v-for="i in 5"
                                    :key="i"
                                >
                                    <v-btn
                                        icon
                                        @click="rating = i"
                                    >
                                        <v-icon
                                            color="#FFA858"
                                            size="32"
                                            v-if="rating >= i"
                                        >
                                            {{ mdiStar }}
                                        </v-icon>
                                        <v-icon
                                            color="#FFA858"
                                            size="32"
                                            v-else
                                        >
                                            {{ mdiStarOutline }}
                                        </v-icon>
                                    </v-btn>
                                    <br />
                                    <span>
                                        {{ i }} Star
                                    </span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Rating -->

                        <!-- Review -->
                        <v-col
                            cols="12"
                            class="os-15-sb pb-1"
                        >
                            Review
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0"
                        >
                            <v-textarea
                                dense
                                outlined
                                color="#2E567A"
                                placeholder="Please provide a short description of your experience…"
                                class="os-13-r"
                                background-color="white"
                                rows="3"
                                :rules="textRules"
                                v-model="review"
                            />
                        </v-col>
                        <!-- Review -->

                        <!-- Rate Button -->
                        <v-col
                            cols="12"
                            class="text-right pt-0"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                :disabled="!validReviewForm"
                                @click="confirmSubmitReview"
                            >
                                Rate
                            </v-btn>
                        </v-col>
                        <!-- Rate Button -->
                    </v-row>
                    <!-- Review Not Completed -->

                    <!-- Review Completed -->
                    <v-row
                        v-else
                    >
                        <v-col
                            cols="12"
                            class="os-15-sb pb-1"
                        >
                            Rating & Review
                        </v-col>
                        <v-col
                            cols="12"
                            class="os-13-r pt-0"
                        >
                            A rating and review of your experience with working with <span class="os-13-sb">{{ revieweeName }}</span> on <span class="os-13-sb">{{ itemName }}</span> has already been submitted successfully.
                        </v-col>
                    </v-row>
                    <!-- Review Completed -->
                </v-form>
            </v-card-text>
        </v-card>

        <!-- Confirm Submit Review Overlay -->
        <work-general-status-confirm-submit-review-overlay-component
            :showConfirmSubmitOverlayBoolean="showConfirmSubmitOverlayBoolean"
            :selectedItem="selectedItem"
            :isService="isService"
            @toggleConfirmSubmitReviewOverlayComponent="toggleConfirmSubmitReviewOverlayComponent"
        />
        <!-- Confirm Submit Review Overlay -->
    </div>
</template>
<script>
    import { mdiStar, mdiStarOutline } from '@mdi/js'
    import WorkGeneralStatusConfirmSubmitReviewOverlayComponent from '@/overlays/work/general/status/WorkGeneralStatusConfirmSubmitReviewOverlayComponent.vue'

    export default {
        name: 'GeneralWorkRatingReviewComponent',

        components: {
            WorkGeneralStatusConfirmSubmitReviewOverlayComponent
        },

        props: {
            selectedItem: {
                type: Object,
                required: true
            },

            isService: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            textRules () {
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field'
                ]
            },

            revieweeName () {
                let name = null
                if (this.isService) {
                    if (this.$store.state.user.userData.id === this.selectedItem.owner.id) {
                        if (this.selectedItem.service.owner.company_id) name = this.selectedItem.service.owner.company_name
                        else name = `${this.selectedItem.service.owner.first_name} ${this.selectedItem.service.owner.last_name}`
                    } else if (this.$store.state.user.userData.id === this.selectedItem.service.owner.id) {
                        if (this.selectedItem.owner.company_id) name = this.selectedItem.owner.company_name
                        else name = `${this.selectedItem.owner.first_name} ${this.selectedItem.owner.last_name}`
                    }
                } else {
                    if (this.$store.state.user.userData.id === this.selectedItem.applicant_id) name = this.selectedItem.company_name
                    else name = `${this.selectedItem.applicant_first_name} ${this.selectedItem.applicant_last_name}`
                }

                return name
            },

            itemName () {
                if (this.isService) return this.selectedItem.service.name
                else return this.selectedItem.job_name
            },

            reviewSubmitted () {
                if (this.isService) {
                    if (this.selectedItem.status.id === 18) return false
                    else if (this.selectedItem.status.id === 21 && this.selectedItem.owner.id === this.$store.state.user.userData.id) return true
                    else if (this.selectedItem.status.id === 20 && this.selectedItem.service.owner.id === this.$store.state.user.userData.id) return true
                    else return false
                } else {
                    if (this.selectedItem.job_status_id === 18) return false
                    else if (this.selectedItem.job_status_id === 21 && this.selectedItem.applicant_id === this.$store.state.user.userData.id) return true
                    else if (this.selectedItem.job_status_id === 20 && this.selectedItem.owner_id === this.$store.state.user.userData.id) return true
                    else return false
                }
            }
        },

        data () {
            return {
                mdiStar,
                mdiStarOutline,
                rating: 1,
                review: '',
                validReviewForm: false,
                showConfirmSubmitOverlayBoolean: false
            }
        },

        methods: {
            confirmSubmitReview () {
                this.toggleConfirmSubmitReviewOverlayComponent(true)
            },

            toggleConfirmSubmitReviewOverlayComponent (value, confirmSubmit) {
                this.showConfirmSubmitOverlayBoolean = value

                if (confirmSubmit) this.submitReview()
            },

            submitReview () {
                this.$emit('submitReview', this.rating, this.review)
            }
        }
    }
</script>
<style scoped>
    >>> div.v-input__slot.white {
        min-height: 0 !important;
        height: 72px !important;
    }
</style>
