<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-02-28 18:14:13
 * @ Description: Component for sending and displaying in-app messages.
 -->

<template>
    <!-- Desktop -->
    <v-row
        v-if="$vuetify.breakpoint.mdAndUp"
        style="height: 100%; max-height: 100%;"
    >
        <v-col
            cols="12"
            style="height: calc(100% - 100px); max-height: calc(100% - 100px); overflow-y: auto;"
            id="messages"
        >
            <v-row>
                <!-- Heading -->
                <v-col
                    cols="12"
                    class="os-15-sb"
                >
                    Messages
                </v-col>
                <!-- Heading -->

                <!-- Loading Indicator -->
                <v-col
                    v-if="twilioLoading"
                    class="os-12-r text-center"
                    style="color: rgba(0, 0, 0, 0.38);"
                >
                    Loading messages...
                    <br />
                    <br />
                    <v-progress-circular
                        indeterminate
                        color="#FFA858"
                    />
                </v-col>
                <!-- Loading Indicator -->

                <!-- No Messages -->
                <v-col
                    v-else-if="!messages || messages.length === 0"
                    class="os-12-r text-center"
                    style="color: rgba(0, 0, 0, 0.38);"
                >
                    No messages
                </v-col>
                <!-- No Messages -->

                <!-- Messages -->
                <v-col
                    v-for="(item, index) in messages"
                    :key="index"
                    cols="10"
                    :offset="isMe(item.author) ? 0 : 2"
                    v-else
                >
                    <v-row>
                        <v-col
                            class="flex-shrink-1 flex-grow-0"
                        >
                            <v-avatar
                                height="46px"
                                width="46px"
                            >
                                <v-img
                                    :src="authorAvatar(item.author) ? authorAvatar(item.author) : '/general/FixerIndividualIcon.png'"
                                    contain
                                />
                            </v-avatar>
                        </v-col>
                        <v-col
                            class="flex-grow-1 flex-shrink-0 os-13-r"
                        >
                            {{ item.body }}
                        </v-col>
                    </v-row>
                </v-col>
                <!-- Messages -->
            </v-row>
        </v-col>
        <v-col
            cols="12"
            style="position: relative;"
        >
            <!-- Type New Message -->
            <v-textarea
                dense
                hide-details
                outlined
                color="#2E567A"
                placeholder="Type message here..."
                class="os-13-r"
                background-color="white"
                rows="3"
                v-model="message"
                :disabled="twilioLoading || !canSendMessages"
            />
            <!-- Type New Message -->

            <!-- Send Button -->
            <v-btn
                style="position: absolute; right: 36px; bottom: -6px;"
                class="white--text no-uppercase rounded-lg os-13-sb"
                color="#2E567A"
                @click="sendMessage"
                :disabled="messageRules"
            >
                Message
                <v-icon
                    color="white"
                    style="opacity: 0.7;"
                    class="ml-1"
                >
                    {{ mdiMessageProcessing }}
                </v-icon>
            </v-btn>
            <!-- Send Button -->
        </v-col>
    </v-row>
    <!-- Desktop -->

    <!-- Mobile -->
    <v-row
        v-else-if="$vuetify.breakpoint.smAndDown"
    >
        <!-- Heading -->
        <v-col
            cols="12"
            class="os-15-sb"
        >
            Messages
        </v-col>
        <!-- Heading -->

        <!-- Loading Indicator -->
        <v-col
            v-if="twilioLoading"
            class="os-12-r text-center"
            style="color: rgba(0, 0, 0, 0.38);"
        >
            Loading messages...
            <br />
            <br />
            <v-progress-circular
                indeterminate
                color="#FFA858"
            />
        </v-col>
        <!-- Loading Indicator -->

        <!-- No Messages -->
        <v-col
            v-else-if="!messages || messages.length === 0"
            class="os-12-r text-center"
            style="color: rgba(0, 0, 0, 0.38);"
        >
            No messages
        </v-col>
        <!-- No Messages -->

        <!-- Messages -->
        <v-col
            v-for="(item, index) in messages"
            :key="index"
            cols="10"
            :offset="isMe(item.author) ? 0 : 2"
            v-else
        >
            <v-row>
                <v-col
                    class="flex-shrink-1 flex-grow-0 text-center"
                >
                    <v-avatar
                        height="46px"
                        width="46px"
                    >
                        <v-img
                            :src="authorAvatar(item.author) ? authorAvatar(item.author) : '/general/FixerIndividualIcon.png'"
                            contain
                        />
                    </v-avatar>
                </v-col>
                <v-col
                    class="flex-grow-1 flex-shrink-0 os-13-r"
                >
                    {{ item.body }}
                </v-col>
            </v-row>
        </v-col>
        <!-- Messages -->

        <v-col
            cols="12"
            style="position: relative;"
        >
            <!-- Type New Message -->
            <v-textarea
                dense
                hide-details
                outlined
                color="#2E567A"
                placeholder="Type message here..."
                class="os-13-r"
                background-color="white"
                rows="3"
                v-model="message"
                :disabled="twilioLoading || !canSendMessages"
            />
            <!-- Type New Message -->

            <!-- Send Button -->
            <v-btn
                style="position: absolute; right: 24px; bottom: -6px;"
                class="white--text no-uppercase rounded-lg os-13-sb"
                color="#2E567A"
                @click="sendMessage"
                :disabled="messageRules"
            >
                Message
                <v-icon
                    color="white"
                    style="opacity: 0.7;"
                    class="ml-1"
                >
                    {{ mdiMessageProcessing }}
                </v-icon>
            </v-btn>
            <!-- Send Button -->
        </v-col>
    </v-row>
    <!-- Mobile -->
</template>
<script>
    import { mdiMessageProcessing } from '@mdi/js'
    import Vue from 'vue'

    export default {
        name: 'GeneralWorkMessagingComponent',

        props: {
            selectedItem: {
                type: Object,
                required: true
            },

            canSendMessages: {
                type: Boolean,
                default: true
            },

            isService: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            messageRules () {
                if (!this.message) return true
                else if (this.message.replace(/\s/g, '') === '') return true
                else return false
            }
        },

        watch: {
            '$store.state.twilio.initialized': {
                async handler (value) {
                    if (value && this.selectedItem.applicant_channel_id) {
                        try {
                            await this.initChat()
                        } catch (error) {}
                    }
                }
            },

            'selectedItem.applicant_channel_id': {
                async handler (value) {
                    if (value && this.$store.state.twilio.initialized) {
                        try {
                            await this.initChat()
                        } catch (error) {}
                    }
                }
            },

            selectedItem: {
                async handler (value, oldValue) {
                    if (oldValue.applicant_channel_id !== value.applicant_channel_id) {
                        if (this.$store.state.twilio.initialized && value.applicant_channel_id) {
                            try {
                                // await this.initChat()
                            } catch (error) {}
                        }
                    }
                },
                deep: true
            },
        },

        data () {
            return {
                mdiMessageProcessing,
                messages: [],
                twilioLoading: true,
                twilioChannel: null,
                message: ''
            }
        },

        beforeDestroy () {
            if (this.twilioChannel) this.twilioChannel.removeListener('messageAdded', this.messageAdded)
        },

        methods: {
            async initChat () {
                try {
                    /* if (!this.twilioChannel) */
                    await this.twilioRetrieveChannel()
                    await this.twilioRetrieveMessages()
                    this.twilioLoading = false

                    this.$nextTick(() => {
                        if (this.$vuetify.breakpoint.mdAndUp) {
                            const messagesElement = document.getElementById('messages')
                            if (messagesElement) {
                                messagesElement.scrollTop = messagesElement.scrollHeight
                            }
                        }
                    })
                } catch (error) {
                    this.initChat()
                }
            },

            async twilioRetrieveChannel () {
                try {
                    if (this.twilioChannel) this.twilioChannel.removeListener('messageAdded', this.messageAdded)
                    
                    this.twilioChannel = await Vue.prototype.$twilio.getChannel(this.selectedItem.applicant_channel_id)
                    this.twilioChannel.on('messageAdded', this.messageAdded)
                } catch (error) {
                    throw Error(error)
                }
            },

            async twilioRetrieveMessages () {
                try {
                    this.messages = []
                    const twilioMessages = await Vue.prototype.$twilio.getChannelMessages(this.twilioChannel)
                    this.messages = twilioMessages.items

                    await this.updateLastReadMessage()
                } catch (error) {
                    throw Error(error)
                }
            },

            async updateLastReadMessage () {
                let lastMessageIndex = 0
                if (this.twilioChannel.lastMessage) lastMessageIndex = this.twilioChannel.lastMessage.index

                await this.twilioChannel.updateLastReadMessageIndex(lastMessageIndex)
                await this.twilioChannel.setAllMessagesRead()

                this.$emit('resetUnreadMessageCount')
            },

            async sendMessage () {
                try {
                    await Vue.prototype.$twilio.sendMessage(this.twilioChannel, this.message)

                    await this.updateLastReadMessage()

                    this.$nextTick(() => {
                        if (this.$vuetify.breakpoint.mdAndUp) {
                            const messagesElement = document.getElementById('messages')
                            if (messagesElement) messagesElement.scrollTop = messagesElement.scrollHeight
                        }
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            isMe (messageAuthorEmail) {
                if (messageAuthorEmail === this.$store.state.user.userData.email) return true
                else return false
            },

            authorAvatar (messageAuthorEmail) {
                if (this.isService) {
                    if (messageAuthorEmail === this.selectedItem.owner.email) return this.selectedItem.owner.avatar
                    else if (messageAuthorEmail === this.selectedItem.service.owner.email) return this.selectedItem.service.owner.avatar
                } else {
                    if (messageAuthorEmail === this.selectedItem.applicant_email) return this.selectedItem.applicant_avatar
                    else if (messageAuthorEmail === this.selectedItem.company_email) return this.selectedItem.company_avatar
                }
            },

            async messageAdded (message) {
                this.messages.push(message)
                this.message = ''
                
                await this.updateLastReadMessage()
            }
        }
    }
</script>
<style scoped>
    >>> div.v-input__slot.white {
        min-height: 0 !important;
        height: 72px !important;
    }
</style>
