<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-02-28 13:25:16
 * @ Description: Overlay to confirm submission of a review.
 -->

<template>
    <!-- Desktop and Bigger Mobiles -->
    <v-dialog
        v-model="showConfirmSubmitOverlayBoolean"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        v-if="$vuetify.breakpoint.smAndUp"
        width="580px"
    >
        <v-card
            color="rgb(249, 251, 253)"
            height="205px"
        >
            <v-card-text
                class="pa-3 pb-0"
                style="color: black;"
            >
                <v-row
                    style="height: 100%;"
                >
                    <!-- Reviewee Details -->
                    <v-col
                        cols="4"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                class="pt-6 pb-0 text-center"
                            >
                                <v-avatar
                                    size="64"
                                >
                                    <v-img
                                        :src="revieweeAvatar ? revieweeAvatar : '/general/FixerIndividualIcon.png'"
                                        contain
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-16-sb text-center"
                            >
                                {{ revieweeName }}
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-12-sb text-center"
                                style="color: #2E567A;"
                            >
                                {{ itemName }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Reviewee Details -->

                    <!-- Confirmation -->
                    <v-col
                        cols="8"
                        style="min-height: calc(100% + 12px); background-color: rgba(255, 168, 88, 0.25); display: flex; align-items: center;"
                    >
                        <v-row
                            class="px-9 py-3"
                        >
                            <v-col
                                cols="12"
                                class="os-18-sb text-center"
                            >
                                Are you sure you want to submit this review for {{ revieweeName }}?
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-13-sb"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                    color="#2E567A"
                                    @click="toggleConfirmSubmitReviewOverlayComponent(false)"
                                >
                                    No
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="toggleConfirmSubmitReviewOverlayComponent(true)"
                                >
                                    Yes
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Confirmation -->
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Desktop and Bigger Mobiles -->

    <!-- Mobile -->
    <v-dialog
        v-model="showConfirmSubmitOverlayBoolean"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        v-else-if="$vuetify.breakpoint.xs"
        style="z-index: 10;"
        max-width="315px"
    >
        <v-card
            color="rgb(249, 251, 253)"
            max-height="450px"
        >
            <v-card-text
                class="pa-3"
                style="color: black;"
            >
                <v-row
                    style="height: 100%;"
                >
                    <!-- Revieweee Details -->
                    <v-col
                        cols="12"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                class="pt-6 pb-0 text-center"
                            >
                                <v-avatar
                                    size="64"
                                >
                                    <v-img
                                        :src="revieweeAvatar ? revieweeAvatar : '/general/FixerIndividualIcon.png'"
                                        contain
                                        class="mx-auto"
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-16-sb text-center"
                            >
                                {{ revieweeName }}
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-12-sb text-center"
                                style="color: #2E567A;"
                            >
                                {{ selectedItem.itemName }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Reviewee Details -->

                    <!-- Confirmation -->
                    <v-col
                        cols="12"
                        style="background-color: rgba(255, 168, 88, 0.25);"
                    >
                        <v-row
                            class="py-3"
                        >
                            <v-col
                                cols="12"
                                class="os-18-sb text-center"
                            >
                                Are you sure you want to submit this review for {{ revieweeName }}?
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-13-sb"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                    color="#2E567A"
                                    @click="toggleConfirmSubmitReviewOverlayComponent(false)"
                                >
                                    No
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="toggleConfirmSubmitReviewOverlayComponent(true)"
                                >
                                    Yes
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Confirmation -->
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Mobile -->
</template>

<script>
    export default {
        name: 'WorkGeneralStatusConfirmSubmitReviewOverlayComponent',

        props: {
            showConfirmSubmitOverlayBoolean: {
                type: Boolean,
                required: true
            },

            selectedItem: {
                type: Object,
                required: true
            },

            isService: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            revieweeName () {
                let name = null
                if (this.isService) {
                    if (this.$store.state.user.userData.id === this.selectedItem.owner.id) {
                        if (this.selectedItem.service.owner.company_id) name = this.selectedItem.service.owner.company_name
                        else name = `${this.selectedItem.service.owner.first_name} ${this.selectedItem.service.owner.last_name}`
                    } else if (this.$store.state.user.userData.id === this.selectedItem.service.owner.id) {
                        if (this.selectedItem.owner.company_id) name = this.selectedItem.owner.company_name
                        else name = `${this.selectedItem.owner.first_name} ${this.selectedItem.owner.last_name}`
                    }
                } else {
                    if (this.$store.state.user.userData.id === this.selectedItem.applicant_id) name = this.selectedItem.company_name
                    else name = `${this.selectedItem.applicant_first_name} ${this.selectedItem.applicant_last_name}`
                }

                return name
            },

            revieweeAvatar () {
                let avatar = null

                if (this.isService) {
                    if (this.$store.state.user.userData.id === this.selectedItem.owner.id) avatar = this.selectedItem.service.owner.avatar
                    else if (this.$store.state.user.userData.id === this.selectedItem.service.owner.id) avatar = this.selectedItem.owner.avatar
                } else {
                    if (this.$store.state.user.userData.id === this.selectedItem.applicant_id) avatar = this.selectedItem.company_avatar
                    else avatar = this.selectedItem.applicant_avatar
                }

                return avatar
            },

            itemName () {
                if (this.isService) return this.selectedItem.service.name
                else return this.selectedItem.job_name
            }
        },

        methods: {
            toggleConfirmSubmitReviewOverlayComponent (confirmSubmit) {
                this.$emit('toggleConfirmSubmitReviewOverlayComponent', false, confirmSubmit)
            }
        }
    }
</script>
